import { Component, OnInit, PipeTransform } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { IndividualCreateComponent } from './individual-create/individual-create.component';
import { Constants } from '@common/global/app.global.constants';
import { GlobalService } from '@common/global/app.global.service';
import { UserLog } from '@common/models/userLog';
import { AppDataServices } from '@store/entity/entity-services';
import { combineLatest, of } from 'rxjs';
import { switchMap, filter } from 'rxjs/operators';
import { User } from '@common/models/user';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-individual',
  templateUrl: './individual.component.html',
  styleUrls: ['./individual.component.scss']
})
export class IndividualComponent implements OnInit {

  roles = Constants.roles;
  //allIndividuals:any = [];
  allUserRoles: any = []
  modalRef: BsModalRef;
  allUsers:any = [];
  currentPage = 1;
	page: number;
  pageSize: number = 10;
  totalItems: number = 0;
	searchText: string;
	searchBy: any;
	searchFields: {
		code: string;
	};
	filterBy: string = '';

	direction: number = 1;
  isDesc: boolean = false;
  sortDirection: string = 'asc';
  column: string = 'firstName';
  selectedUserInfo:any = null;
  modalType: string = 'NEW';
  userLogs: UserLog = new UserLog();
  currentuser;
  teamData;
  team;
  users:User[] = [];
  reports:any[];
  currentEnterprise;
  enterpriseId;

  constructor(private appDataServices: AppDataServices, private globals:GlobalService, private modalService: BsModalService, private route: ActivatedRoute) {
    this.currentuser = (this.globals.currentUser ||{}).user;
    this.getLists();
    this.pageSize = this.globals.pageSize;
    this.searchBy = this.getSearchObject();
  }

  ngOnInit() {
  }

  get isAdmin() {
    return this.globals.currentUserRoleId== Constants.roles.ADMINISTRATOR;
  }

  getLists() {
		this.allUserRoles = [];
		this.allUsers = [];
		this.globals.showLoading();
    let {enterpriseId} = this.route.snapshot.params;
    this.enterpriseId = enterpriseId;

		this.appDataServices.loaded$.pipe(filter(loaded=>!!loaded),
    switchMap(()=>
			combineLatest([
				(!this.isAdmin || !enterpriseId )? this.appDataServices.userService.users$ : this.appDataServices.userService.getUsers(enterpriseId),
        this.appDataServices.commonService.userRoles$,
			  this.appDataServices.enterpriseService.collection$,
			  this.appDataServices.enterpriseService.currentEnterprise$,
        this.reports ? of(this.reports) :
          (this.enterpriseId ? this.appDataServices.calculatorService.loadReports(this.enterpriseId) :
            this.appDataServices.calculatorService.allReports$),
			]))
		).subscribe(([ users, roles, collection, currentEnterprise, reports])=>{
      this.reports = reports;
      const userReports = (users||[]).map(user=>{
        const report = this.reports.find(item=> +item.userId === +user.id && item.type=='individual')
        return report? {...user, report} : user;
      })
      this.allUsers = userReports || [];
      this.allUserRoles = roles || [];
      this.globals.hideLoading();
      this.currentEnterprise = (this.isAdmin && enterpriseId ) ? collection.entities[enterpriseId || this.globals.currentEnterpriseId] : currentEnterprise;

		})

    
	}

  refreshPageData(){
  //  this.loadUsers();
  }

  refreshSearch() {
		this.searchBy = this.getSearchObject();
	}

  getSearchObject() {
		let searchObj = {};
		if (this.filterBy == '') {
			//searchObj['user_name'] = this.searchText;
			searchObj['firstName'] = this.searchText;
			searchObj['lastName'] = this.searchText;
      searchObj['email'] = this.searchText;
      searchObj['roleName'] = this.searchText;
      searchObj['designation'] = this.searchText;
      // searchObj['employee_id'] = this.searchText;
      searchObj['phoneNumber'] = this.searchText;
		}    
		return searchObj;
	}
 
	pageChanged(event: any): void {
    this.currentPage = event.page;
    this.page = event.page;
	}

	sortBykey(key) {
		this.direction = this.isDesc ? 1 : -1;
		this.isDesc = !this.isDesc;
    this.column = key;
    if(this.direction == 1){
      this.sortDirection = 'asc';
    }else{
      this.sortDirection = 'desc';
    }
	}


  openIndividualModal(){
    const initialState = {
      individualRoles: this.allUserRoles.filter(item=>item.roleNumber!=this.roles.ADMINISTRATOR),
      modalType: this.modalType,
      individualData: this.selectedUserInfo,
      enterpriseData: this.currentEnterprise,
      allUsers: this.allUsers
    };
    this.modalRef = this.modalService.show(IndividualCreateComponent, {
      initialState,
      class: 'gray modal-lg',
      backdrop: 'static'
     });

    this.modalRef.content.event.subscribe(()=>{ 
      if(this.isAdmin)
        this.appDataServices.userService.getUsers(this.enterpriseId).subscribe(users=>{
          this.allUsers = users || [];
        })
    });


  }

  importUsers(event) {
    let hasError = false;
    let usernames = this.allUsers.map(item=>item.username);
    this.users = [];
    const reader = new FileReader();
    reader.readAsText(event.target.files[0]);
    reader.onload = (e: any) => {
        let csvToRowArray = e.target.result.trim().split("\n");
        let titles;
        csvToRowArray.forEach(item => {
          if(!titles){
            titles = item.trim().split(",")||[];
          } else {
             let row: any[] = item.trim().split(",")||[];
             if(row.length==titles.length){
              let user:any = row.reduce((acc, curr, index)=>{
                return {...acc, [titles[index]]:curr}
              },{})
  
              if(!usernames.includes(user.email)) {
                let roles = (user.roles||this.roles.TEAM.toString()).split(';')
                .filter(item=>item>1) // can't be product owner
                .map(roleNumber=>({roleNumber: +roleNumber, name:''}));
                this.users.push(new User({...user, roles, enterpriseId: +user.enterpriseId || +this.currentuser.enterpriseId}));
              }
             } else {
              hasError = true;
             }
            
            
          }          
        });
        this.createUsers(hasError);
    };
  }

  generateRandomPassword(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  createUsers(hasError) {
      const initialState = {
        individualRoles: [],
        modalType: 'NEW',
        individualData: null,
        allUsers: this.allUsers,
        importUsers: this.users,
        hasError
        
      };
      this.modalRef = this.modalService.show(IndividualCreateComponent, {
        initialState,
        class: 'gray modal-lg',
        backdrop: 'static'
       });
  
      this.modalRef.content.event.subscribe(data => {
        if(data == 'true'){
          this.appDataServices.userService.add(this.users)
          .subscribe(() =>{
            if(this.isAdmin)
              this.appDataServices.userService.getUsers(this.enterpriseId);
            else
              this.appDataServices.userService.load().subscribe()
          },()=>{

          })
        }
      });
  }


  createUser(){
    this.modalType = 'NEW';
    this.selectedUserInfo = null;
    this.openIndividualModal();
  }
  editStore(user){
    this.modalType = 'EDIT';
    this.selectedUserInfo = user;

    if(this.globals.currentUserRoleId == this.roles.INDIVIDUAL){
      this.globals.showErrorMessage('You are not allowed to do this action');
    }else if(this.globals.currentUserRoleId == this.roles.TEAM){
      if(parseInt(this.selectedUserInfo.role_id) == this.roles.INDIVIDUAL){
        this.openIndividualModal();
      }else{
        this.globals.showErrorMessage('You are not allowed to do this action');
      }
    }else{
      this.openIndividualModal();
    }



  }
  designation(user) {
    return ((user||{}).designation||'').toLowerCase()!='null'? ((user||{}).designation||null) : null;
  }
  isCurrentUser(user){
    return this.globals.currentUserId == user.id;
  }

  openDeleteConfirmDialog(user:User){
    if(this.globals.currentUserRoleId !== this.roles.ENTERPRISE){
      this.globals.showErrorMessage('You are not allowed to do this action');
      return;
    }

    let additionalMsg = 'Will be removed from any associated teams as well';
    /*swal.fire({
      html:'<span style="font-size:large;">Are you sure you want to delete? </span> <span style="font-size:large; color:red; font-weight:bold;">'+user.firstName+' '+user.lastName+' ?</span> <br/> <span style="font-size:small; line-height: 3;">'+additionalMsg+'</span>',
      showCancelButton: true,
      confirmButtonColor: 'var(--green-90-70)',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      imageUrl:'assets/img/Question-48.png',
      iconHtml: '<img src="assets/img/Question-48.png" style="width:45px; height:45px;"/>',
      allowOutsideClick: false,
        showClass: {
          popup: 'animated fadeInDown faster'
        },
        hideClass: {
          popup: 'animated fadeOutUp faster'
        },
        allowEscapeKey: false
    })*/
    let msg= '<span style="font-size:large;">Are you sure you want to delete </span> <span style="font-size:large; color:red; font-weight:bold;">'+user.firstName+' '+user.lastName+' ?</span> <br/> <span style="font-size:small; line-height: 3;">'+additionalMsg+'</span>';
		this.globals.showConfirm(msg, true, true, 'Delete', 'Cancel', 'confirm-danger','confirm-success')
    .subscribe((result) => {
      if (result) {
        this.sendDeleteRequestToServer(user);
      }
    })
  }

  sendDeleteRequestToServer(user){
    this.globals.showLoading();
    this.appDataServices.userService.delete(user).subscribe(
      (result) => {
        this.globals.hideLoading();
        if (result) {          
          this.globals.showSuccessMessage('User deleted successfully');
          this.createUserLog('User deleted successfully.',user.userId);
        }
      },
      (error) => {
        this.globals.hideLoading();
         try{
          let errorDetails = error.error.detailedMsg.originalError.info.message;
          if(errorDetails.indexOf('dbo.ps_teams') != -1){
            this.globals.showErrorMessage('Please delete teams which are managing by '+(user.name || user.firstName));
          }
        }catch(e){
          //nothing to do
        }
      }
    );

  }

  /*showMessageAndLoadValues(msg){
      swal.fire({
        title: title,
        allowEscapeKey: false,
        showCloseButton: false,
        imageUrl: 'assets/img/OK-48.png',
        allowOutsideClick: false,
        showClass: {
          popup: 'animated fadeInDown faster'
        },
        hideClass: {
          popup: 'animated fadeOutUp faster'
        }
      })
        let msg= '<span style="font-size:large;">Are you sure you want to delete? </span> <span style="font-size:large; color:red; font-weight:bold;">'+user.firstName+' '+user.lastName+' ?</span> <br/> <span style="font-size:small; line-height: 3;">'+additionalMsg+'</span>';
        this.globals.showConfirm(msg, true, true, 'Yes', 'Cancel', 'confirm-danger','confirm-success')
        .then((result) => {
        if (result.value) {
          //this.loadUsers();
        }
    });
  }*/

  createUserLog(activity, description){
    this.userLogs.activity = activity;
    this.userLogs.description = description;
    this.userLogs.enterpriseId = this.globals.currentEnterpriseId.toString();
    this.userLogs.userId = this.globals.currentUserRoleId.toString();
  }

}
