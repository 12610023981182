import { Component, OnInit, PipeTransform } from '@angular/core';
import { GlobalService } from '@common/global/app.global.service';
import { Enterprise, EnterpriseCategory } from '@common/models/enterprise';
import { Constants } from '@common/global/app.global.constants';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CreateComponent } from './create/create.component';
import { AppDataServices } from '@store/entity/entity-services';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  allEnterprises: Enterprise[] = [];
  enterpriseCategories: EnterpriseCategory[] = [];
  modalRef: BsModalRef;
  searchText: string;
	searchBy: any;
	searchFields: {
		name: string;
	};
	filterBy: string = '';
  constructor( private globals:GlobalService,private modalService: BsModalService,  private appDataServices: AppDataServices ) {
    this.loadEnterprises();
    this.loadEnterpriseCategories();
    this.searchBy = { name: this.searchText, country: this.searchText };
  }

  ngOnInit() {
  }

  refreshSearch() {
		this.searchBy = this.getSearchObject();
	}

	getSearchObject() {
		let searchObj:any = {};
		if (this.filterBy == '') {
			searchObj['name'] = this.searchText;
			searchObj['country_name'] = this.searchText;
		} else if (this.filterBy == 'name') {
			searchObj['name'] = this.searchText;
		} else if (this.filterBy == 'country') {
			searchObj['country_name'] = this.searchText;
		}
		return searchObj;
	}



  openEnterpriseDetailsModel(){
    const initialState = {
      enterpriseCategories: this.enterpriseCategories,
      type: 'NEW',
      enterpriseData: null
    };
    this.modalRef = this.modalService.show(CreateComponent, {
      initialState,
      class: 'gray modal-lg',
      backdrop: 'static'
     });
    this.modalRef.content.enterpriseData = null;
    this.modalRef.content.type = 'NEW';

    this.modalRef.content.event.subscribe();

  }

  sendEnterpriseInfoForEdit(enterprise){
    const initialState = {
      enterpriseCategories: this.enterpriseCategories,
      type: 'EDIT',
      enterpriseData: enterprise
    }
    this.modalRef = this.modalService.show(CreateComponent, Object.assign({}, this.modalRef, { class: 'gray modal-lg', initialState }));
    this.modalRef.content.enterpriseData = enterprise;
    this.modalRef.content.type = 'EDIT';
    this.modalRef.content.event.subscribe()
  }

  loadEnterprises(){
    this.globals.showLoading();
      this.appDataServices.enterpriseService.enterprises$.subscribe(
        (result) => {
          this.globals.hideLoading();
          if(result){
            this.allEnterprises = result;
         } else{
            this.allEnterprises = [];
            this.globals.showErrorMessage(Constants.MSG_LOADING_DATA_FAILED);
          }
        }, () =>{
          this.globals.hideLoading(); 
          this.allEnterprises = [];
        });
  }

  loadEnterpriseCategories() {
    this.appDataServices.enterpriseService.getEnterpriseCategories().subscribe(
      (result) => {
        if (result) {
          this.enterpriseCategories = result || [];
        } else {
          this.enterpriseCategories = [];
        }
      },
      () => {
        this.enterpriseCategories = [];
      }
    );
  }

}
