<form  novalidate (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm">
   <div class="modal-header" style="background-color: transparent;  color: var(--btn-primary-color); border: 1px transparent;">
      <div style="width:100%; font-size:large; ">Enterprise Information</div>
      <button style="color:var(--btn-primary-color);" type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true" style="color:var(--btn-primary-color);">&times;</span>
      </button>
   </div>
   <div class="modal-body modal-lg" style=" background-color: transparent; border: 1px transparent; max-width: 100% !important;">
      <div class="row">
         <div class="col-8 ">
            <div class="row ">
               <div class="col-12">
                  <div class="form-group border-bottom border-primary">
                     <label for="" style="font-weight: bold;">General Info</label>
                  </div>
               </div>
            </div>
            <div class="row">
               <div class="col-6">
                  <div class="form-group">
                     <label for="role_id">Enterprise Name<span style="color:red;">*</span></label>
                     <input type="text" minlength="3" maxlength="100" autocomplete="off" placeholder="Enterprise Name" name="name" 
                     class="form-control input-style1 bg-grey" [(ngModel)]="enterpriseInfo.name" #name="ngModel" 
                     [ngClass]="{ 'is-invalid': name.errors && (name.dirty || name.touched)}" required>
                     <div *ngIf="name.errors && (name.dirty || name.touched)" class="invalid-feedback">
                         <div *ngIf="name.errors.required">Enterprise Name is required</div>
                         <div *ngIf="name.errors.minlength">Name is minimum {{ name.errors.minlength.requiredLength }} characters.</div>
                         <div *ngIf="name.errors.maxlength">Name is maximum 100 characters.</div>
                     </div>
                  </div>
               </div>
               <div class="col-6" style="padding-left:3px ;">
                  <div class="form-group">
                     <label for="role_id">Category<span style="color:red;">*</span></label>
                     <select name="categoryId" class="form-control input-style1 bg-grey" 
                     [(ngModel)]="enterpriseInfo.categoryId" #categoryId="ngModel" [ngClass]="{ 'is-invalid': categoryId.errors && (categoryId.dirty || categoryId.touched) }" required>
                       <option value="">Select Category</option>
                       <option *ngFor="let category of enterpriseCategories" value={{category.id}}>
                          {{category.name}}
                       </option>
                   </select>
                   <div *ngIf="categoryId.errors && (categoryId.dirty || categoryId.touched)" class="invalid-feedback">
                       <div *ngIf="categoryId.errors.required">Category is required</div>
                   </div>

                  </div>
               </div>
            </div>
            <div class="row">
               <div class="col-6">
                  <div class="form-group">
                     <label for="role_id">Email<span style="color:red;">*</span></label>
                     <input type="text" autocomplete="off" minlength="5" maxlength="100" placeholder="Email" name="email" class="form-control  input-style1 bg-grey"
                      [(ngModel)]="enterpriseInfo.email" #email="ngModel" 
                     [ngClass]="{ 'is-invalid': email.errors && (email.dirty || email.touched) }" required email>
                     <div *ngIf="email.errors && (email.dirty || email.touched)" class="invalid-feedback">
                         <div *ngIf="email.errors.required">Email is required</div>
                         <div *ngIf="email.errors.email">Email is not valid</div>
                         <div *ngIf="email.errors.minlength">Email is minimum {{ email.errors.minlength.requiredLength }} characters.</div>
                         <div *ngIf="email.errors.maxlength">Email is maximum 100 characters.</div>
                     </div>

                  </div>
               </div>
               <div class="col-6" style="padding-left:3px ;">
                  <div class="form-group">
                     <label for="role_id">Website<span style="color:red;">*</span></label>
                     <input type="text" autocomplete="off" minlength="5" maxlength="100" placeholder="Website" name="website" class="form-control  input-style1 bg-grey" [(ngModel)]="enterpriseInfo.website" #website="ngModel" 
                     [ngClass]="{ 'is-invalid': website.errors && (website.dirty || website.touched) }" required>
                     <div *ngIf="website.errors && (website.dirty || website.touched)" class="invalid-feedback">
                         <div *ngIf="website.errors.required">Website is required</div>
                         <div *ngIf="website.errors.minlength">Website is minimum {{ website.errors.minlength.requiredLength }} characters.</div>
                         <div *ngIf="website.errors.maxlength">Website is maximum 100 characters.</div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="col-4">
            <div class="row ">
               <div class="col-12">
                  <div class="form-group border-bottom border-primary">
                     <label for="" style="font-weight: bold;">Company Logo</label>
                  </div>
               </div>
            </div>
            <div class="form-group">
               <div class="row">
                  <div class="col-12 text-center">
                     <figure class="figure">
                        <img style="max-width:100px;max-height: 100px;" src="{{enterpriseInfo.logo}}" class="figure-img img-fluid rounded" alt="">
                     </figure>
                  </div>
               </div>
               <div class="row">
                  <div class="col-12 text-center" id="uploadImg">
                    <input
                    style="display: none"
                    type="file" (change)="onFileChanged($event)" accept="image/*"
                    #fileInput>
                     <button type="button" class="btn btn-light " style="border: 1px grey !important;" (click)="fileInput.click()">Select Logo</button> <br/>
                     <span style="font-size: xx-small; color: red;">
                        Logo max size is 500KB
                     </span>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="row">
         <div class="col-8 ">
            <div class="row ">
               <div class="col-12">
                  <div class="form-group border-bottom border-primary">
                     <label for="" style="font-weight: bold;">Location Info</label>
                  </div>
               </div>
            </div>
            <div class="row">
              <div class="col-12">
                 <div class="form-group">
                   <label for="role_id">Address<span style="color:red;">*</span></label>
                   <!-- ngx-google-places-autocomplete  -->
                   <input  placeholder="Enter Address.." (blur)="handleAddressChange($event)" appGooglePlacesAutocomplete
                     name="address" class="form-control  input-style1 bg-grey" [(ngModel)]="enterpriseInfo.address" #addressGooglePlaces
                     #address="ngModel" [ngClass]="{ 'is-invalid': address.errors && (address.dirty || address.touched) }" required>
                   <div *ngIf="address.errors && (address.dirty || address.touched)" class="invalid-feedback">
                     <div *ngIf="address.errors.required">Address is required</div>
                     <div *ngIf="address.errors.minlength">Address is minimum
                       {{ address.errors.minlength.requiredLength }} characters.</div>
                     <div *ngIf="address.errors.maxlength">Address is maximum 100 characters.</div>
                   </div>
     
                 </div>
               </div>               
            </div>
            <div class="row">
               <div class="col-12">
                  <div class="form-group border-bottom border-primary">
                     <label for="" style="font-weight: bold;">Users Limits</label>
                  </div>
               </div>
            </div>
            <div class="row">
               <div class="col-6 form-group">
                  <label for="role_id">Max Enterprise Users</label>
                  <input type="number"
                     name="maxEnterpriseUsers" class="form-control  input-style1 bg-grey" [(ngModel)]="enterpriseInfo.maxEnterpriseUsers">      
               </div>
               <div class="col-6 form-group">
                  <label for="role_id">Max Users</label>
                  <input type="number"
                     name="maxUsers" class="form-control  input-style1 bg-grey" [(ngModel)]="enterpriseInfo.maxUsers">      
               </div>
            </div>
         </div>
         <div class="col-4">
            <div class="row ">
               <div class="col-12">
                  <div class="form-group border-bottom border-primary">
                     <label for="" style="font-weight: bold;">Contact Info</label>
                  </div>
               </div>
            </div>
            <div class="form-group">
               <div class="row">
                  <div class="col-4" >
                     <div class="form-group">
                       <label for="role_id">Title<span style="color:red;">*</span></label>
                        <select style="padding: 0px;" [(ngModel)]="enterpriseInfo.contactTitle" name="contactTitle" class="form-control  input-style1 bg-grey" required #contactTitle="ngModel">
                        <option value="Mr">Mr</option>
                        <option value="Mrs">Mrs</option>
                        <option value="Miss">Miss</option>
                        <option value="Ms">Ms</option>
                        <option value="Sir">Sir</option>
                        <option value="Dr">Dr</option>
                        </select>
                     </div>
                  </div>
                  <div class="col-8" style="padding-left:2px ;">
                     <div class="form-group">
                       <label for="role_id">Contact Name<span style="color:red;">*</span></label>
                        <input autocomplete="off" minlength="5" maxlength="100"  type="text" placeholder="Name" id="contactName" class="form-control  input-style1 bg-grey" [(ngModel)]="enterpriseInfo.contactName" name="contactName" required #contactName="ngModel" 
                        [ngClass]="{ 'is-invalid': contactName.errors && (contactName.dirty || contactName.touched) }">
                        <!-- <div class="alert alert-danger"
                           *ngIf="contactName.errors && (contactName.dirty || contactName.touched)">
                           Name is required
                        </div> -->
                        <div  *ngIf="contactName.errors && (contactName.dirty || contactName.touched)" class="invalid-feedback">
                          <div *ngIf="contactName.errors.required">Name is required</div>
                          <div *ngIf="contactName.errors.minlength">Name is minimum {{ contactName.errors.minlength.requiredLength }} characters.</div>
                         <div *ngIf="contactName.errors.maxlength">Name is maximum 100 characters.</div>
                       </div>
                     </div>
                  </div>
                  <!--<div  class="alert alert-danger"
                     *ngIf="contactTitle.errors && (contactTitle.dirty || contactTitle.touched)">
                     Title is required
                  </div> -->
               </div>
               <div class="row">
                  <div class="col-12">
                     <div class="form-group">
                       <label for="role_id">Contact Email<span style="color:red;">*</span></label>
                        <input minlength="5" maxlength="100" autocomplete="off" type="text" placeholder="Email" id="contactEmail"
                         [(ngModel)]="enterpriseInfo.contactEmail" class="form-control  input-style1 bg-grey" name="contactEmail" required #contactEmail="ngModel"
                          [ngClass]="{ 'is-invalid': contactEmail.errors && (contactEmail.dirty || contactEmail.touched) }" email>
                        <!-- <div class="alert alert-danger"
                           *ngIf="contactEmail.errors && (contactEmail.dirty || contactEmail.touched)">
                           Email is required
                        </div> -->
                        <div  *ngIf="contactEmail.errors && (contactEmail.dirty || contactEmail.touched)" class="invalid-feedback">
                          <div *ngIf="contactEmail.errors.required">Email is required</div>
                          <div *ngIf="contactEmail.errors.email">Email is not valid</div>
                          <div *ngIf="contactEmail.errors.minlength">Email is minimum {{ contactEmail.errors.minlength.requiredLength }} characters.</div>
                          <div *ngIf="contactEmail.errors.maxlength">Email is maximum 100 characters.</div>
                       </div>
                     </div>
                  </div>
               </div>
               <div class="row">
                  <div class="col-12">
                     <div class="form-group">
                       <label for="role_id">Contact Phone<span style="color:red;">*</span></label>
                        <input autocomplete="off" type="text" placeholder="Phone Number" id="contactPhone" name="contactPhone" minlength="7" pattern="[\s-+.()\d]+"
                         [(ngModel)]="enterpriseInfo.contactPhone" class="form-control  input-style1 bg-grey" required #contactPhone="ngModel" 
                         [ngClass]="{ 'is-invalid': contactPhone.errors && (contactPhone.dirty || contactPhone.touched) }">
                        
                        <div  *ngIf="contactPhone.errors && (contactPhone.dirty || contactPhone.touched)" class="invalid-feedback">
                          <div *ngIf="contactPhone.errors.required">Phone is required</div>
                          <div *ngIf="contactPhone.errors.minlength">Phone is minimum {{ contactPhone.errors.minlength.requiredLength }} digits.</div>
                         
                          <div *ngIf="contactPhone.errors.pattern">Phone is invalid</div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="row">
         <div class="col-12 ">
            <div class="row ">
               <div class="col-12">
                  <div class="form-group border-bottom border-primary">
                     <label for="" style="font-weight: bold;">Enterprise Features</label>
                  </div>
               </div>
            </div>
            <div class="form-group">
               <div class="row">
                 <div class="col-3 text-left">
                    <div class="checkbox">
                       <label>
                       <input type="checkbox" id="actions" name="actions"  [disabled]="features.supplier_only || features.enterprise_only" [(ngModel)]="features.actions"> &nbsp;Actions (Core)
                       </label>
                    </div>
                 </div>
                 <div class="col-3 text-left">
                  <div class="checkbox">
                     <label>
                     <input type="checkbox" id="goals" name="goals"  [disabled]="features.supplier_only || features.enterprise_only" [(ngModel)]="features.goals"> &nbsp;Goals (Core)
                     </label>
                  </div>
               </div>
               <div class="col-3 text-left">
                  <div class="checkbox">
                     <label>
                     <input type="checkbox" id="calculator" name="calculator"  [(ngModel)]="features.calculator" (change)="featuresChanged()"> &nbsp;Calculator (Core)
                     </label>
                  </div>
                 </div>
                 <div class="col-3 text-left">
                    <div class="checkbox">
                       <label>
                       <input type="checkbox" id="dashboard" name="dashboard"  [disabled]="!features.calculator" [(ngModel)]="features.dashboard"> &nbsp;Dashboard
                       </label>
                    </div>
                 </div>                 
                 <div class="col-3 text-left">
                    <div class="checkbox">
                       <label>
                       <input type="checkbox" id="eco_print" name="eco_print"  [disabled]="features.supplier_only || features.enterprise_only || !features.actions" [(ngModel)]="features.eco_print"> &nbsp;Eco-Print
                       </label>
                    </div>
                 </div>
                 <div class="col-3 text-left">
                  <div class="checkbox">
                     <label>
                     <input type="checkbox" id="kpi" name="kpi"  [disabled]="features.supplier_only || features.enterprise_only" [(ngModel)]="features.kpi"> &nbsp;KPI
                     </label>
                  </div>
               </div>
               <div class="col-3 text-left">
                  <div class="checkbox">
                     <label>
                     <input type="checkbox" id="documents" name="documents"  [disabled]="features.supplier_only || features.enterprise_only" [(ngModel)]="features.documents"> &nbsp;Documents
                     </label>
                  </div>
               </div>
               <div class="col-3 text-left">
                  <div class="checkbox">
                     <label>
                     <input type="checkbox" id="users" name="users"  [disabled]="features.supplier_only || features.enterprise_only" [(ngModel)]="features.users"> &nbsp;Users
                     </label>
                  </div>
               </div>
               <div class="col-3 text-left">
                  <div class="checkbox">
                     <label>
                     <input type="checkbox" id="teams" name="teams"  [disabled]="features.supplier_only || features.enterprise_only" [(ngModel)]="features.teams"> &nbsp;Teams
                     </label>
                  </div>
               </div>
               <div class="col-3 text-left">
                  <div class="checkbox">
                     <label>
                     <input type="checkbox" id="suppliers" name="suppliers"  [disabled]="features.supplier_only || features.enterprise_only" [(ngModel)]="features.suppliers"> &nbsp;Suppliers
                     </label>
                  </div>
               </div>
               <div class="col-3 text-left">
                  <div class="checkbox">
                     <label>
                     <input type="checkbox" id="reductions" name="reductions" [disabled]="!features.calculator || features.supplier_only || features.enterprise_only" [(ngModel)]="features.reductions"> &nbsp;Reductions
                     </label>
                  </div>
               </div>    
                 <div class="col-3 text-left">
                    <div class="checkbox">
                       <label>
                       <input type="checkbox" id="white_label" name="white_label"  [(ngModel)]="features.white_label"> &nbsp;White Label
                       </label>
                    </div>
                 </div>
                 <div class="col-3 text-left">
                    <div class="checkbox">
                       <label>
                       <input type="checkbox" id="enterprise_only" name="enterprise_only" [(ngModel)]="features.enterprise_only" (change)="featuresChanged()"> &nbsp;Enterprise only
                       </label>
                    </div>
                 </div>
                 <div class="col-3 text-left">
                  <div class="checkbox">
                     <label>
                     <input type="checkbox" id="supplier_only" name="supplier_only" [(ngModel)]="features.supplier_only" (change)="supplierChanged()"> &nbsp;Supplier only
                     </label>
                  </div>
               </div> 
               <div class="col-3 text-left">
                  <div class="checkbox">
                     <label>
                     <input type="checkbox" id="supplier_calculator" name="supplier_calculator" [disabled]="!features.suppliers" [(ngModel)]="features.send_supplier_calculator" > &nbsp;Send Supplier Calculator
                     </label>
                  </div>
               </div>
               <div class="col-3 text-left">
                  <div class="checkbox">
                     <label>
                     <input type="checkbox" id="supplier_form" name="supplier_form" [disabled]="!features.suppliers" [(ngModel)]="features.send_supplier_form" > &nbsp;Send ESG Survey
                     </label>
                  </div>
               </div>  
                                            
               </div>
            </div>
         </div>

      </div>
   </div>
   <div class="modal-footer justify-content-center">


      <button type="submit"  class="btn btn-save" style="border-radius: 7px;">
      Save
      </button>

      <button *ngIf="type!='NEW'" type="button" class="btn btn-danger close-button-color" (click)="deleteEnterprise()">
       Delete
     </button>
   </div>
   </form>
