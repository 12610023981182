
<div class="p-3">
  <div class="d-flex flex-row">
    <div class="d-flex flex-column">
      <h4 class="title">People</h4>
      <div class="title d-flex gap-2" style="font-size: 14px;" *ngIf="isAdmin && enterpriseId">
        <a  style=" cursor: pointer;  color: var(--header-color) !important;"  [routerLink]="['/home']">Home</a>
        <span>|</span>
        <span>{{currentEnterprise?.name}}</span>
      </div>
    </div>
    
      
    
    <div class="ml-auto toolbar w-100 d-flex flex-row align-items-center justify-content-end gap-2">
      <div class="w-auto form-group position-relative has-search mb-0 pe-2">
        <span class="fa fa-search form-control-feedback fa-lg" style="line-height: 2.3;"></span>
        <input type="text" (keyup)="refreshSearch()" class="form-control w-100" [(ngModel)]="searchText" #txtSearch
          placeholder="Search ">          
      </div>        
        <button (click)="createUser()" class="btn btn-primary" >
          <i class="fa fa-plus mr-2"></i>Add
        </button>
        <button (click)="uploadImg.click()" class="btn btn-primary" >
          <i class="fa fa-plus mr-2"></i>Import
        </button>
        <input type="file" #uploadImg id="upload-img" class="d-none" (change)="importUsers($event)" accept=".csv">          
    </div>  
  </div>
  <p class="pt-1 text-right text-muted">please use this <a style="color:var(--btn-primary-color)" href="assets/data/users.csv">file</a> as a guide for user import</p> 
  

     
      <div class="mt-4 d-flex flex-row flex-wrap gap-2">
        <div class="card text-center p-0 mr-3"  *ngFor="let item of allUsers   | searchpipe: searchBy;">
          <div class="d-flex flex-column gap-1 position-absolute m-3">
            <div class="circle" [class.completed]="!!item.report?.published" matTooltip="COMPLETE"></div>
            <div class="circle" [class.inProgress]="!!item.report && !item.report?.published" matTooltip="IN PROGRESS"></div>
            <div class="circle" [class.undone]="!item.report" matTooltip="ACTION REQUIRED"></div>
          </div>
          <i class="ml-auto fa fa-ellipsis-v text-fade m-2" style="padding: 5px; z-index: 10;" [matMenuTriggerFor]="userMenu"></i>
          <div style="margin-top: -25px;" (click)="editStore(item)" >
            <img class="avatar mb-2" [src]="item.profilePhoto || 'assets/img/account.svg'" 
            [class.opacity-25]="!item.profilePhoto || item.profilePhoto==='assets/img/account.svg'">
            <h4 class="name my-0 "> {{item.name || (item.firstName + ' ' + item.lastName)}} </h4>
            <h6 class="email my-0 px-1">{{item.email || '&nbsp;'}}</h6>
            <h6 class="bar ">{{designation(item)|| '&nbsp;'}}</h6>
          </div>
          

          <mat-menu #userMenu="matMenu">
            <span class="px-2 text-muted">USER SETTINGS</span>
            <button mat-menu-item class="ps-2" (click)="editStore(item)">
              <span> <i class="fa fa-edit pr-2" style="color:#C0C0C0; width: 20px;"></i></span>
              <span>Edit user</span>
            </button>
            <button mat-menu-item class="ps-2 text-danger" *ngIf="!isCurrentUser(item)" (click)="openDeleteConfirmDialog(item)">
              <span> <i class="fa fa-trash text-danger pr-2" style="width: 20px;"></i></span>
              <span class="text-danger">Delete user</span>
            </button>
          </mat-menu>

        </div>
      </div>

</div>
