<div class="individual-dialog">
    <div class="d-flex flex-column" *ngIf="importUsers">
        <div class="d-flex flex-row gap-1">
           <h4>Import users</h4>
            <button type="button" class="ml-auto close text-muted" aria-label="Close" (click)="close()">
                <span aria-hidden="true" >&times;</span>
            </button>
           
        </div>
        <div class="mt-3 d-flex flex-column" style="min-height: 150px; overflow-y: auto;">
              <div class="d-flex flex-row gap-2" *ngFor="let item of importUsers">
                 <h6>{{item.name}} ({{item.email}})</h6> 
              </div>
              <p *ngIf="hasError" class="text-danger p-0">
               There are errors in the imported file, please revise and make sure there is no extra comma(,)<br>
               we parsed the valid users.
              </p>
              <div *ngIf="!isViewOnly && (isEnterprise || isAdmin)" class="mt-auto text-center">
                 <button  (click)="createUsers()" form="f" class="btn btn-save">Submit</button>
              </div>
        </div>
       
     
   </div>
  
  <form id="f" *ngIf="!importUsers" novalidate (ngSubmit)="(isEnterprise || isAdmin) && f.form.valid && createIndividual()" #f="ngForm">
     <div class="d-flex flex-column justify-content-center" style="min-height: 30px;">
        <div class="d-flex flex-row ml-auto justify-content-end align-items-center gap-1">
            <button type="button" class="close text-muted ml-auto pl-0" aria-label="Close" (click)="close()">
                <span aria-hidden="true" >&times;</span>
            </button>
        </div>
    </div>
     <div class="d-flex flex-row align-items-center justify-content-center gap-2 position-relative"  >
        <div class="avatar position-absolute" alt="" style="margin-top: -100px;" >
              <img (click)="(isEnterprise || isAdmin) ? uploadImg.click() : null"
              [class.opacity-25]="isDarkMode && (!individualInfo.profilePhoto || individualInfo?.profilePhoto==='assets/img/account.svg')"
        src="{{individualInfo?.profilePhoto || 'assets/img/account.svg'}}" >
           
        </div>
        
        <input type="file" #uploadImg id="upload-img" class="d-none" (change)="onFileChanged($event)" accept="image/*">
      </div>
     <div class="d-flex flex-row mt-4 pt-3 gap-4">
         <div class="form-group w-50">
             <label for="user_firstName">First name <span style="color:red;">*</span></label>
             <input type="text" class="form-control  input-style1 bg-grey" [(ngModel)]="individualInfo.firstName" name="user_firstName"  #user_firstName="ngModel"
              [ngClass]="{ 'is-invalid':  user_firstName.invalid && (f.submitted || !f.pristine) }" required
                 id="user_firstName" [disabled]="isViewOnly || (!isEnterprise && !isAdmin)">
                 <div *ngIf="user_firstName.invalid && (f.submitted || !f.pristine)" class="invalid-feedback">
                         <div *ngIf="user_firstName.errors?.required">First Name is required</div>
                 </div>
         </div>   
         <div class="form-group w-50">
             <label for="user_lastName">Last name <span style="color:red;">*</span></label>
             <input type="text" class="form-control  input-style1 bg-grey" [(ngModel)]="individualInfo.lastName" name="user_lastName"  #user_lastName="ngModel" 
             [ngClass]="{ 'is-invalid':  user_lastName.invalid && (f.submitted || !f.pristine) }" required
                 id="user_lastName"  [disabled]="isViewOnly || (!isEnterprise && !isAdmin)">
                 <div *ngIf="user_lastName.invalid && (f.submitted || !f.pristine)" class="invalid-feedback">
                         <div *ngIf="user_lastName.errors?.required">Last Name is required</div>
                 </div>
         </div>   
  
     </div>
     <div class="d-flex flex-row mt-2 pt-3 gap-4">
         <div class="form-group w-50">
             <label for="user_middle_name">Email <span style="color:red;">*</span></label>
             <input type="email" class="form-control input-style1 bg-grey" [(ngModel)]="individualInfo.email" name="user_email" #user_email="ngModel" 
             [ngClass]="{ 'is-invalid':  user_email.invalid && (f.submitted || !f.pristine) }" email required
                 id="user_email"  [disabled]="isViewOnly || (!isEnterprise && !isAdmin)">
                 <div *ngIf="user_email.invalid && (f.submitted || !f.pristine)" class="invalid-feedback">
                         <div *ngIf="user_email.errors?.required">Email is required</div>
                         <div *ngIf="user_email.errors?.email">Email must be a valid email address</div>
                 </div>
         </div>
         <div class="form-group w-50">
             <label for="user_phoneNumber">Phone number </label>
             <input type="tel" class="form-control input-style1 bg-grey" [(ngModel)]="individualInfo.phoneNumber" name="user_phoneNumber"  #user_phoneNumber="ngModel" 
             [ngClass]="{ 'is-invalid':  user_phoneNumber.invalid && (f.submitted || !f.pristine) }"
                 id="user_phoneNumber"  [disabled]="isViewOnly || (!isEnterprise && !isAdmin)"
                 pattern="[-+()\d]+">
                 <div *ngIf="user_phoneNumber.invalid && (f.submitted || !f.pristine)" class="invalid-feedback">
                      <div *ngIf="user_phoneNumber.invalid">Phone Number is invalid</div>
                 </div>
         </div>
  
     </div>
     <div class="d-flex flex-row gap-4">
         <div class="form-group w-50">
             <label for="user_employeeId">Employee id </label>
             <input type="text" class="form-control input-style1 bg-grey" [(ngModel)]="individualInfo.employeeId" name="user_employeeId"  #user_employeeId="ngModel" 
             [ngClass]="{ 'is-invalid':  user_employeeId.invalid && (f.submitted || !f.pristine) }"
                 id="user_employeeId"  [disabled]="isViewOnly || (!isEnterprise && !isAdmin)">
                 <div *ngIf="user_employeeId.invalid && (f.submitted || !f.pristine)" class="invalid-feedback">
                         <div *ngIf="user_employeeId.errors?.required">Employee Id is required</div>
                 </div>
         </div>
         <div class="form-group w-50">
           <label for="roleId">Role(s)<span style="color:red;">*</span></label>
           <mat-select name="roleId" [disabled]="isViewOnly || (!isEnterprise && !isAdmin)" class="form-control input-style1 bg-grey" style="line-height: 35px;" multiple
              [(ngModel)]="individualInfo.roles" #roleId="ngModel" (ngModelChange)="notSaved=true" [compareWith]="roleCompare"
              [ngClass]="{ 'is-invalid': roleId.invalid && (f.submitted || !f.pristine)}" required>
              <!--<mat-option value="">Select Role</mat-option>-->
              <mat-option *ngFor="let role of individualRoles" [value]="role">
                 {{role.name}}
              </mat-option>
           </mat-select>
           <div *ngIf="roleId.invalid && (f.submitted || !f.pristine)" class="invalid-feedback">
              <div *ngIf="roleId.errors?.required">Role is required</div>
           </div>
  
        </div>
  
     </div>  
     <div class="form-group">
        <label for="designation">Position <span style="color:red;">*</span></label>
        <input type="text" minlength="0" maxlength="100" autocomplete="off" placeholder="Position" required
           name="designation" class="form-control input-style1 bg-grey" [(ngModel)]="individualInfo.designation" (ngModelChange)="notSaved=true"
           #designation="ngModel" [ngClass]="{ 'is-invalid': designation.invalid && (f.submitted || !f.pristine) }"
           [disabled]="isViewOnly || (!isEnterprise && !isAdmin)">
         <div *ngIf="designation.invalid && (f.submitted || !f.pristine)" class="invalid-feedback">
          <div *ngIf="designation.errors?.required">Position is required</div>
      </div>
     </div>
     <div class="row" *ngIf="isEnterprise || isAdmin">
        <div class="col-12 ">
           <div class="row ">
              <div class="col-12">
                 <div class="form-group border-bottom border-primary">
                    <label for="" style="font-weight: bold;">User Features</label>
                 </div>
              </div>
           </div>
           <div class="form-group">
              <div class="row">
                <div class="col-3 text-left">
                   <div class="checkbox">
                      <label>
                      <input type="checkbox" id="actions" name="actions"  [disabled]="features.enterprise_only || !enterprise_features.actions" (change)="featuresChanged()" [(ngModel)]="features.actions"> &nbsp;Actions (Core)
                      </label>
                   </div>
                </div>
                <div class="col-3 text-left">
                    <div class="checkbox">
                       <label>
                       <input type="checkbox" id="goals" name="goals"  [disabled]="features.enterprise_only || !enterprise_features.goals" [(ngModel)]="features.goals"> &nbsp;Goals (Core)
                       </label>
                    </div>
                 </div>
                <div class="col-3 text-left">
                 <div class="checkbox">
                    <label>
                    <input type="checkbox" id="calculator" name="calculator" [disabled]="!enterprise_features.calculator"  [(ngModel)]="features.calculator" (change)="featuresChanged()"> &nbsp;Calculator (Core)
                    </label>
                 </div>
                </div>
                <div class="col-3 text-left">
                   <div class="checkbox">
                      <label>
                      <input type="checkbox" id="dashboard" name="dashboard"  [disabled]="!features.calculator || !enterprise_features.dashboard" [(ngModel)]="features.dashboard"> &nbsp;Dashboard
                      </label>
                   </div>
                </div>                 
                <div class="col-3 text-left">
                   <div class="checkbox">
                      <label>
                      <input type="checkbox" id="eco_print" name="eco_print"  [disabled]="!features.actions || !enterprise_features.eco_print" [(ngModel)]="features.eco_print"> &nbsp;Eco-Print
                      </label>
                   </div>
                </div>
                 <div class="col-3 text-left">
                    <div class="checkbox">
                       <label>
                       <input type="checkbox" id="kpi" name="kpi"  [disabled]="features.enterprise_only || !enterprise_features.kpi" [(ngModel)]="features.kpi"> &nbsp;KPI
                       </label>
                    </div>
                 </div>
                 <div class="col-3 text-left">
                    <div class="checkbox">
                       <label>
                       <input type="checkbox" id="documents" name="documents"  [disabled]="features.enterprise_only || !enterprise_features.documents" [(ngModel)]="features.documents"> &nbsp;Documents
                       </label>
                    </div>
                 </div>
                 <div class="col-3 text-left">
                    <div class="checkbox">
                       <label>
                       <input type="checkbox" id="users" name="users"  [disabled]="features.enterprise_only || !enterprise_features.users || (this.isCurrentUser && features.users)" [(ngModel)]="features.users"> &nbsp;Users
                       </label>
                    </div>
                 </div>
                 <div class="col-3 text-left">
                    <div class="checkbox">
                       <label>
                       <input type="checkbox" id="teams" name="teams"  [disabled]="features.enterprise_only || !enterprise_features.teams " [(ngModel)]="features.teams"> &nbsp;Teams
                       </label>
                    </div>
                 </div>
                 <div class="col-3 text-left">
                  <div class="checkbox">
                     <label>
                     <input type="checkbox" id="suppliers" name="suppliers"  [disabled]="features.enterprise_only || !enterprise_features.suppliers " [(ngModel)]="features.suppliers"> &nbsp;Suppliers
                     </label>
                  </div>
               </div>
               <div class="col-3 text-left">
                  <div class="checkbox">
                     <label>
                     <input type="checkbox" id="reductions" name="reductions"  [disabled]="!features.calculator || features.enterprise_only || !enterprise_features.calculator || !enterprise_features.reductions " [(ngModel)]="features.reductions"> &nbsp;Reductions
                     </label>
                  </div>
               </div>    
                    
                <div class="col-3 text-left">
                   <div class="checkbox">
                      <label>
                      <input type="checkbox" id="white_label" name="white_label" [disabled]="!enterprise_features.white_label" [(ngModel)]="features.white_label"> &nbsp;White Label
                      </label>
                   </div>
                </div>
                <div class="col-3 text-left">
                   <div class="checkbox">
                      <label>
                      <input type="checkbox" id="enterprise_only" name="enterprise_only" [disabled]="!enterprise_features.enterprise_only" [(ngModel)]="features.enterprise_only" (change)="featuresChanged()"> &nbsp;Enterprise only
                      </label>
                   </div>
                </div>
                <div class="col-3 text-left">
                  <div class="checkbox">
                     <label>
                     <input type="checkbox" id="supplier_only" name="supplier_only" [disabled]="!enterprise_features.supplier_only" [(ngModel)]="features.supplier_only" (change)="supplierChanged()"> &nbsp;Supplier only
                     </label>
                  </div>
               </div>
               <div class="col-3 text-left">
                  <div class="checkbox">
                     <label>
                     <input type="checkbox" id="supplier_calculator" name="supplier_calculator" [disabled]="!enterprise_features.send_supplier_calculator" [(ngModel)]="features.send_supplier_calculator" > &nbsp;Send Supplier Calculator
                     </label>
                  </div>
               </div>
               <div class="col-3 text-left">
                  <div class="checkbox">
                     <label>
                     <input type="checkbox" id="supplier_form" name="supplier_form" [disabled]="!enterprise_features.send_supplier_form" [(ngModel)]="features.send_supplier_form" > &nbsp;Send ESG Survey
                     </label>
                  </div>
               </div>
              </div>
           </div>
        </div>

     </div> 
     <div class="text-center">
        <button *ngIf="!isViewOnly && (isEnterprise || isAdmin)" type="submit" form="f" [disabled]="(!isEnterprise && !isAdmin) || (!fileChanged && f.form.pristine) || f.form.invalid"
         class="btn btn-save">Save</button>
         <small class="float-right" style="color:#E74C3C">* Required</small>
     </div>
     
     </form>
</div>
