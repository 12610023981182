<form  novalidate (ngSubmit)="f.form.valid && createNewTeam()" #f="ngForm">
     <div class="modal-body py-0">
      <div class="d-flex flex-column justify-content-center">
         <div class="d-flex flex-row ml-auto justify-content-end align-items-center gap-1">
             <button type="button" class="close text-muted ml-auto pl-0" aria-label="Close" (click)="closeModal()">
                 <span aria-hidden="true" >&times;</span>
             </button>
         </div>
         <div class="form-group text-center">
          <input type="text" appFocus autocomplete="off" 
           placeholder="Enter team name" name="title" class="form-control title border-0 " 
           [(ngModel)]="teamInfo.name"  #title="ngModel"
           [ngClass]="{ 'is-invalid': (f.submitted || !f.pristine) && title.invalid }" required>
          <div *ngIf="(f.submitted || !f.pristine || title.dirty || title.touched) && title.invalid" class="invalid-feedback pr-5"  style="margin-top: -5px !important;">
              <div *ngIf="title.errors.required || title.invalid ">Team name is required</div>
          </div>
       </div>        
         
     </div>

         <div class="form-group">
            <label for="name">Description</label>
            <input type="text" autocomplete="off" minlength="0" maxlength="200" placeholder="Description" name="description" class="form-control input-style1 bg-grey" [(ngModel)]="teamInfo.description" #description="ngModel">
         </div>

         <div class="form-group">
            <label for="name">Team manager<span style="color:red;">*</span></label>

               <select name="teamManagerId" [(ngModel)]="teamInfo.teamManagerId"   #teamManagerId="ngModel" class="form-control  input-style1 bg-grey" [ngClass]="{ 'is-invalid': (f.submitted || !f.pristine) && teamManagerId.invalid }" required>
                  <option value="">Select team manager</option>
                 <option *ngFor="let manager of teamManagers" [ngValue]="manager">{{manager.name}}</option>
               </select>
               <div *ngIf="(f.submitted || !f.pristine) && teamManagerId.invalid" class="invalid-feedback">
                  <div *ngIf="teamManagerId.errors.required">Team Manager is required</div>
              </div>

         </div>

         <div class="form-group">
            <label for="name">Team members</label>
            <app-select-users inputClass="border-0 input-style1 bg-grey" 
                            [(ngModel)]="teamInfo.teamMembers"
                            [labelField]="'name'"
                            name="assignedTo"
                            [users]="teamMembers"
                            placeholder="Select members">
                        </app-select-users>
         </div>
      <div class="text-center">
        <button  *ngIf="isTeamManager" type="submit" class="btn btn-save">
            Save
        </button>
        <small class="position-absolute" style="right: 25px; color:#E74C3C">* Required</small>
      </div>
    </div>

</form>