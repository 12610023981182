<div>
<div class="row">



  <div class="col-3" style="padding:28px; padding-left: 3%;">
    <div class="row">
     <span style="font-weight: bold; color:var(--btn-primary-color); padding-left: 5px; line-height: 0.8;">
        ENTERPRISES ({{allEnterprises.length}})
      </span>
    </div>
  </div>


    <div class="col-9" style="padding-top:15px;">
        <table style="width:100%;">
            <tr>
                <td style="align-content: right; text-align: right; float: right;">
                  <table>
                    <tr>                      
                        <td style="line-height: 3; ">
                          &nbsp;&nbsp;&nbsp;&nbsp; <button (click)="openEnterpriseDetailsModel()" class="btn btn-primary pull-right" style="line-height: 1.5; font-size: small; background-color: var(--btn-primary-color);">
                            <i class="fa fa-plus "></i>
                            &nbsp;Create</button>
                        </td>
                    </tr>
                </table>
                </td>
            </tr>
        </table>
    </div>



</div>
<hr>



</div>


<!-- OLD Code -->

<div class="row">




  <div *ngFor="let enterprise of allEnterprises  | searchpipe: searchBy" class="col-xl-2 col-lg-3 col-md-3 col-sm-6 grid-margin stretch-card">
    <div class="card text-center cardHeaderSettings cardBodySettings">
      <div class="card-header cardHeaderSettings" style="align-content: center; text-align: center; padding:10px;">

        <div class="enterpriseLogo" style="margin:0 auto;">
          <img id="imgEnterpriseLogo" [src]="enterprise.logo||'assets/img/noImage.png'" (error)="enterprise.logo='assets/img/noImage.png'"/>
      </div>


      </div>
      <div class="card-body cardBodySettings cardBodyBackColor">
        <div class="truncate"
          style="width:100%;vertical-align: middle; font-weight: bold; color: #ffffff; background-color: var(--btn-primary-color); padding:10px;  text-align: center;">
          {{enterprise.name}}
        </div>
        <div style="width:100%; line-height: 2.0; color: #ffffff; padding:5px; height: 100px; text-align: center;">
          {{enterprise.website}} <br />
          {{enterprise.email}}<br />
          {{enterprise.country_name}}<br />

        </div>       
          <div class="d-flex flex-row cardBodySettings" style="width:100%" style="background-color: var(--btn-primary-color); cursor: pointer;  opacity: 0.8;">
            <a class="p-1" [routerLink]="['/individuals', enterprise.id]">
              <svg  viewBox="0 0 35 35" class="svg-people">
                <use xlink:href='assets/icons/sidebar/team.svg#img'></use>
              </svg>
            </a>
            
            <a (click)="sendEnterpriseInfoForEdit(enterprise)"  class="m-auto pr-4 small-box-footer" style="color:#ffffff;line-height: 2.2;">View More&nbsp;
              <i class="fa fa-arrow-circle-right"></i>
            </a>
        </div>
        
      </div>

    </div>

  </div>



  <div *ngIf="allEnterprises.length == 0" style="width:100%; padding-left:1%">
    <div  class="alert alert alert-danger fade show" style="background-color: var(--btn-primary-color); border:solid 1px var(--btn-primary-color); color:#ffffff;">
      <strong>Info!</strong> No enterprises yet. Create to explore more.
      <button type="button" class="close" data-dismiss="alert"></button>
    </div>
  </div>
  <div *ngIf="(allEnterprises | searchpipe: searchBy ).length == 0 && allEnterprises.length > 0" style="width:100%; padding-left:1%">
    <div class="alert alert-warning fade show" style="background-color: var(--btn-primary-color); border:solid 1px var(--btn-primary-color);  color:#ffffff;">
      <strong>No Matches Found</strong>
      <button type="button" class="close" data-dismiss="alert"></button>
    </div>
  </div>

</div>