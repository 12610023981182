import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TeamCreateComponent } from './team-create/team-create.component';
import { Constants } from '@common/global/app.global.constants';
import { GlobalService } from '@common/global/app.global.service';
import { UserLog } from '@common/models/userLog';
import { AppDataServices } from '@store/entity/entity-services';
import { filter, switchMap } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { IndividualCreateComponent } from '../individual/individual-create/individual-create.component';


@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss']
})
export class TeamsComponent implements OnInit {
  roles = Constants.roles;
  filterBy: string = "";
  modalRef: BsModalRef;
  allTeamManagers:any = [];
  allTeamMembers:any[]=[];
  allUsers:any[]=[];
  allTeams:any[]=[];
  allRoles:any[]=[];
  filteredTeams:any[]=[];
  goalcolors : any = [];
  searchText: string;
  modalType: string = 'NEW';
  selectedTeamInfo:any;
	direction: number;
	isDesc: boolean = false;
  column: string;
  searchBy: any;
  sortDirection: string = 'asc';
  currentPage = 1;
	page: number;
  pageSize: number;
  totalItems: number = 0;
  userLogs: UserLog = new UserLog();
  roleid;
  currentuser;
  selectedTeam:any = "";

  constructor(private appDataServices: AppDataServices, private globals:GlobalService, private modalService: BsModalService) {
  }

  ngOnInit() {
    this.getLists();
    this.pageSize = this.globals.pageSize;
    this.searchBy = this.getSearchObject();
    this.roleid = this.globals.currentUserRoleId;
    this.currentuser = this.globals.currentUser.user;
  }

  getLists() {
		this.allTeams = [];
		this.allUsers = [];
		this.globals.showLoading();
		this.appDataServices.loaded$.pipe(filter(loaded=>!!loaded),
    switchMap(()=>
			combineLatest([
				this.appDataServices.teamService.teams$,
				this.appDataServices.userService.users$,
        this.appDataServices.commonService.userRoles$
			]))
		).subscribe(([teams, users, roles])=>{
      this.allUsers = users || [];
      this.allTeamManagers = this.allUsers.filter(user=>(user.roles||[]).map(item=>item.roleNumber).includes(this.roles.TEAM));
      this.allRoles = roles || [];
      this.allTeams = teams || [];
      this.totalItems = this.allTeams.length;
			this.filterTeam();        
      this.globals.hideLoading();

		})

    
	}

  get isTeamOrEnterpriseManager(){
    return this.globals.getRole(this.roles.TEAM) ||
     this.globals.getRole(this.roles.ENTERPRISE);
  }

  get isEnterpriseAdmin(){
    return this.globals.getRole(this.roles.ENTERPRISE);
  }

  filterTeam() {
    this.filteredTeams = this.allTeams.filter(item=>item.id==this.selectedTeam || this.selectedTeam=='');
    this.totalItems = this.filteredTeams.length;
  }

  designation(user) {
    return ((user||{}).designation||'').toLowerCase()!='null'? ((user||{}).designation||null) : null;
  }

  createTeam(){
    this.modalType = 'NEW';
    this.selectedTeamInfo = null;
    this.openTeamModal();
  }

  editTeam(user){
    if(this.isTeamOrEnterpriseManager) {
      this.modalType = 'EDIT';
      this.selectedTeamInfo = user;
      this.openTeamModal();
    }
  }

  openIndividualModal(team, selectedUserInfo){
    let isManager = (team.teamManager||{}).userId && this.globals.currentUserId == (team.teamManager||{}).userId;
    if(this.isEnterpriseAdmin || isManager) {
      const initialState = {
        individualRoles: this.allRoles.filter(item=>item.role_id!=this.roles.ADMINISTRATOR),
        modalType: 'VIEW',
        individualData: selectedUserInfo,
        allUsers: this.allUsers
      };
      this.modalRef = this.modalService.show(IndividualCreateComponent, {
        initialState,
        class: 'gray modal-lg',
        backdrop: 'static'
      });
    }
  }

  openDeleteConfirmDialog(user){
    // console.log('Selected User for Delete');
    // console.log(user);
    /*swal.fire({
      html:'<span style="font-size:large;">Are you sure you want to delete? </span> <span style="font-size:large; color:red; font-weight:bold;"> ' + user.name + ' ?</span>',
      showCancelButton: true,
      confirmButtonColor: 'var(--green-90-70)',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      imageUrl:'assets/img/Question-48.png',
      iconHtml: '<img src="assets/img/Question-48.png" style="width:45px; height:45px;"/>',
      allowOutsideClick: false,
        showClass: {
          popup: 'animated fadeInDown faster'
        },
        hideClass: {
          popup: 'animated fadeOutUp faster'
        },
        allowEscapeKey: false
    })*/
      let msg= '<span style="font-size:large;">Are you sure you want to delete </span> <span style="font-size:large; color:red; font-weight:bold;">'+ user.name + ' ?</span>';
      this.globals.showConfirm(msg, true, true, 'Delete', 'Cancel', 'confirm-danger','confirm-success')
      .subscribe((result) => {
      if (result) {
        this.sendDeleteTeamRequestToServer(user);
      }
    })
  }

  setheadercolor(){
    const goalColors = ['#FF00FF', '#0066FF', '#1B99F3', '#39B54A', '#FF8D09', '#8968CD', '#00A5A8', '#FFCA00', '#F5423C', '#6E00B1', '#99CC00', '#C304CE' ];
    let i = Math.floor(Math.random()*11);
    let RandomColor = goalColors[i];
    // console.log(RandomColor)
    return RandomColor
  }

  openTeamModal(){
    const initialState = {
      modalType: this.modalType,
      individualData: this.selectedTeamInfo,
      teamMembers: this.allUsers,
      teamManagers: this.allTeamManagers,
      teams:this.allTeams ||[]
    };
    this.modalRef = this.modalService.show(TeamCreateComponent, {
      initialState,
      class: 'gray modal-lg',
      backdrop: 'static'
     });

    this.modalRef.content.eventT.subscribe();
  }


  sortBykey(key) {
		this.direction = this.isDesc ? 1 : -1;
		this.isDesc = !this.isDesc;
    this.column = key;

  }

  refreshSearch() {
		this.searchBy = this.getSearchObject();
	}

  getSearchObject() {
		let searchObj = {
      name: this.searchText,
      firstName: this.searchText,
      lastName: this.searchText,
      email: this.searchText,
      designation: this.searchText,
    };
    return searchObj;
  }


  pageChanged(event: any): void {
		this.page = event.page;
	}


  sendDeleteTeamRequestToServer(team){
    this.globals.showLoading();
    this.appDataServices.teamService.delete(team).subscribe(
      (result) => {
        this.globals.hideLoading();
      },(err)=>{
        this.globals.hideLoading();
      });
  }

}
