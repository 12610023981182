import { switchMap } from 'rxjs/operators';
import { ThemeToggleService } from '@common/services/theme-toggle.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, Validators, FormBuilder, NgForm } from '@angular/forms';
import { GlobalService } from '@common/global/app.global.service';
import { Constants } from '@common/global/app.global.constants';
import { UserLog } from '@common/models/userLog';
import { AppDataServices } from '@store/entity/entity-services';
import { Enterprise } from '@models/enterprise';

@Component({
  selector: 'app-individual-create',
  templateUrl: './individual-create.component.html',
  styleUrls: ['./individual-create.component.scss']
})
export class IndividualCreateComponent implements OnInit {
  public event: EventEmitter<any> = new EventEmitter();
  public individualData;
  public enterpriseData:Enterprise;
  public modalType;
  public type;
  public importUsers = null;
  public hasError; // has error in import csv

  fileChanged = false;
  roles = Constants.roles;
  notSaved = false;
  allUsers:any = [];
  individualRoles: any = [];
  //role_id: string = '';
  individualInfo = {
    "username": "",
    "password": "",
    "firstName": "",
    "lastName": "",
    "email": "",
    "profilePhoto": "assets/img/account.svg",
    //"roleId": 3,
    "enterpriseId": '',
    "designation": "",
    "employeeId": "",
    "phoneNumber": "",
    "roles":[],
    "preferences": {features:null}
  };
  userLogs: UserLog = new UserLog();
  features:any = {};
  enterprise_features:any = {};
  
  constructor(private appDataServices: AppDataServices, public bsModalRef: BsModalRef, 
    private themeService: ThemeToggleService, private globals: GlobalService) {
   }

  ngOnInit() {
    if(this.modalType != 'NEW'){
      this.individualInfo = Object.assign({}, this.individualData);    
    }
    if((this.individualInfo.roles||[]).length==0) {
      this.individualInfo = {...this.individualInfo, roles: this.individualRoles.filter(item=>item.roleNumber==this.roles.TEAM)}
    }
    if(!this.enterpriseData) {
      this.appDataServices.enterpriseService.currentEnterprise$.subscribe(enterpriseData=>{
        this.enterpriseData = enterpriseData || {};
        this.initFeatures();
      })
    } else 
      this.initFeatures();
    

    

    
  }

  initFeatures(){
    this.enterprise_features = {...this.enterpriseData?.preferences?.features};
    for (const key in this.enterprise_features) {
      if((this.enterprise_features||{})[key]!=false && (this.individualInfo?.preferences?.features||{})[key]!=false)
        this.features[key]=true;
      else
        this.features[key]=false;
    }
  }
  

  featuresChanged() {
    this.features = {...this.features,
        dashboard: !this.features.calculator ? false: this.features.dashboard,
        reductions: !this.features.calculator? false: this.features.reductions,
        actions: this.features.enterprise_only? false: this.features.actions,
        goals: this.features.enterprise_only? false: this.features.goals,
        eco_print: (this.features.enterprise_only || !this.features.actions)? false: this.features.eco_print,
        documents: this.features.enterprise_only? false: this.features.documents,
        kpi: this.features.enterprise_only? false: this.features.kpi,
        users: this.features.enterprise_only? false: this.features.users,
        teams: this.features.enterprise_only? false: this.features.teams,
        suppliers: this.features.enterprise_only? false: this.features.suppliers,
        supplier_only: this.features.enterprise_only ? false: this.features.supplier_only,
    }
  }

  supplierChanged() {
    this.features = {...this.features,
        enterprise_only: this.features.supplier_only ? false: this.features.enterprise_only,
    }
  }

  get isDarkMode() {
    return this.themeService.theme.includes('-dark');
  }
  get isAdmin(){
    return this.globals.getRole(this.roles.ADMINISTRATOR) ;
  }
  get isEnterprise(){
    return this.globals.getRole(this.roles.ENTERPRISE);
  }

  get isViewOnly() {
    return this.modalType != 'EDIT' && this.modalType != 'NEW';
  }

  get currentUserRoleId() {
    return this.globals.currentUserRoleId;
  }

  createUsers() {
    this.event.emit('true');
    this.bsModalRef.hide();
  }

  roleCompare(item1, item2) {
    return +item1.roleNumber == +item2.roleNumber;
  }

  get isCurrentUser() {
    return this.globals.currentUserId == this.individualData?.id;
  }

  onFileChanged(event) {
    this.fileChanged = false;
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    let fileZie = event.target.files[0].size;
    if(fileZie <= 512000){
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        this.individualInfo.profilePhoto = e.target.result;
        this.fileChanged = true;
      }, (err => {
        this.individualInfo.profilePhoto = this.individualInfo.profilePhoto || "assets/img/account.svg";
      });
    }else{
      this.globals.showErrorMessage('Profile Image max size is 500KB.');
      return;
    }

  }

  generateRandomPassword(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  createIndividual() {
    let userdata = {
      ...this.individualInfo,
      username: this.individualInfo.email,
      roles: (this.individualInfo.roles||[]).map(({name,roleNumber})=>({name,roleNumber})),
      enterpriseId: this.individualInfo.enterpriseId || this.globals.currentEnterpriseId,
      phoneNumber: this.individualInfo.phoneNumber || '',
      preferences: {...this.individualInfo.preferences, features:this.features}
    };

    let isEmailIdAlreadyExists = false;
    if(this.modalType == 'NEW'){
      for(var i = 0; i< this.allUsers.length; i++) {
        if(userdata.email == this.allUsers[i].user_name) {
          isEmailIdAlreadyExists = true;
          break;
        }
      }
    }

    if(isEmailIdAlreadyExists) {
        this.globals.showErrorMessage('Email already exists. Please choose another email!');
    }else {
      this.globals.showLoading();
      if(this.modalType == 'NEW'){
        this.appDataServices.userService.add([userdata]).subscribe(
          (result) => {
            this.globals.hideLoading();
            // refresh users & teams too             
            if(!this.isAdmin) {
              this.appDataServices.userService.load().subscribe();
              if(result.id==this.globals.currentUserId)
                this.appDataServices.authService.load().subscribe();
            }
              
            this.event.emit('true');
            this.bsModalRef.hide();
          },
          (error) => {
            this.globals.hideLoading();
          }
        );
      }else{
        this.globals.showLoading();
        this.appDataServices.userService.update(userdata).subscribe(
          (result) => {
            // refresh users & teams too             
            if(!this.isAdmin) {
              this.appDataServices.userService.load().subscribe();
              if(result.id==this.globals.currentUserId)
                this.appDataServices.authService.load().subscribe();
            }
            
            this.globals.hideLoading();
            this.event.emit('true');
            this.bsModalRef.hide();

          },
          (error) => {
            this.globals.hideLoading();
          }
        );
      }
    }
  }


  close(){
    this.bsModalRef.hide();
  }

  closeModal(f: NgForm){
    if(this.notSaved) {
      this.confirmCloseSave(f);
    } else {
      this.close();
    }    
  }

  confirmCloseSave(f: NgForm) {
    this.globals.showConfirm('Are you sure you want to leave without saving your changes?', true, true, 'Save Changes', 'Discard Changes', 'confirm-success', 'confirm-danger')
    .subscribe((result) => {
      if (result) {
        if(f.form.valid)
        this.createIndividual();
      }
      else
       this.close();
    });
  }

  openDeleteConfirmDialog(){
    
    /*swal.fire({
      html:'<span style="font-size:large;">Are you sure you want to delete? </span> <span style="font-size:large; color:red; font-weight:bold;">'+this.individualInfo.firstName+' '+this.individualInfo.lastName+' ?</span>',
      showCancelButton: true,
      confirmButtonColor: 'var(--green-90-70)',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      imageUrl:'assets/img/Question-48.png',
      iconHtml: '<img src="assets/img/Question-48.png" style="width:45px; height:45px;"/>',
      allowOutsideClick: false,
        showClass: {
          popup: 'animated fadeInDown faster'
        },
        hideClass: {
          popup: 'animated fadeOutUp faster'
        },
        allowEscapeKey: false
    })*/
      let msg= '<span style="font-size:large;">Are you sure you want to delete </span> <span style="font-size:large; color:red; font-weight:bold;">'+this.individualInfo.firstName+' '+this.individualInfo.lastName+' ?</span>';
      this.globals.showConfirm(msg, true, true, 'Delete', 'Cancel', 'confirm-danger','confirm-success')
      .subscribe((result) => {
      if (result) {
        this.sendDeleteRequestToServer(this.individualData);
      }
    })
  }

  sendDeleteRequestToServer(user){
    this.globals.showLoading();
    this.appDataServices.userService.delete(user).subscribe(
      (result) => {
        // console.log(result);
        this.globals.hideLoading();
        if (result) {
          this.globals.showSuccessMessage("User deleted successfully");
          this.createUserLog('User deleted successfully.',user.id);

        }else{
          this.globals.hideLoading();
         // this.globals.showErrorMessage('Something went wrong. Please try later!');
        }
      },
      (error) => {
        this.globals.hideLoading();
      }
    );

  }

  createUserLog(activity, description){
    this.appDataServices.userService.createUserLog(activity, description).subscribe();
  }


}
