import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { GlobalService } from '@common/global/app.global.service';
import { Constants } from '@common/global/app.global.constants';
import { Enterprise, EnterpriseCategory } from '@common/models/enterprise';
import { FormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { AppDataServices } from '@store/entity/entity-services';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css']
})
export class CreateComponent implements OnInit {

  @ViewChild('addressGooglePlaces') addressGooglePlaces: any;
  public event: EventEmitter<any> = new EventEmitter();

  public enterpriseData:any;
  public type:any;


  enterpriseCategories: EnterpriseCategory[] = [];
  title: string='';
  enterpriseCategory: number = 0;
  countries: any[] = [];
  noResultForCountries = false;
  noResultForStates = false;
  noResultForCities = false;
  selectedCountryId: number = 0;
  selectedStateId: number = 0;
  selectedCityId: number = 0;
  enterpriseCountry: string = '';
  enterpriseState: string = '';
  enterpriseCity: string = '';
  states: any[] = [];
  cities: any[] = [];
  userId:any;

  mainButtonText: string = 'Create Enterprise';

  enterpriseInfo: Enterprise = new Enterprise({
    "name": "",
    "categoryId": 0,
    "email": "",
    "website": "",
    "logo": "assets/img/noImage.png",
    "contactName": "",
    "contactTitle": "Mr",
    "contactEmail": "",
    "contactPhone": "",
    "preferences": null,    
    "address": "",
    "addressLine1": "",
    "addressLine2": "",
    "pincode": "",
    "maxUsers": null ,
    "maxEnterpriseUsers": null,
    "isActive": true
  });


  features:any = {goals:true, actions:true, dashboard:true, calculator:true, eco_print:true, white_label:false,
                  documents:true, kpi:true, users:true, teams:true, suppliers:false, reductions:true};
  enterpriseName: string = '';

  constructor(private fb: UntypedFormBuilder,  private appDataServices: AppDataServices, private modalService: BsModalService, public bsModalRef: BsModalRef, private globals: GlobalService) {

  }

  ngOnInit() {
    if (this.type == 'EDIT') {
      this.mainButtonText = 'Update Enterprise';
      this.enterpriseInfo = this.enterpriseData ? new Enterprise({...this.enterpriseData}) : this.enterpriseInfo;
    }
    this.features = {...((this.enterpriseInfo.preferences||{}).features || this.features)};
  }

  featuresChanged() {
    this.features = {...this.features,
        dashboard: !this.features.calculator ? false: this.features.dashboard,
        reductions: !this.features.calculator? false: this.features.reductions,
        actions: this.features.enterprise_only? false: this.features.actions,
        goals: this.features.enterprise_only? false: this.features.goals,
        eco_print: (this.features.enterprise_only || !this.features.actions)? false: this.features.eco_print,
        documents: this.features.enterprise_only? false: this.features.documents,
        kpi: this.features.enterprise_only? false: this.features.kpi,
        users: this.features.enterprise_only? false: this.features.users,
        teams: this.features.enterprise_only? false: this.features.teams,
        suppliers: this.features.enterprise_only? false: this.features.suppliers,
        supplier_only: this.features.enterprise_only ? false: this.features.supplier_only,
    }
  }

  supplierChanged() {
    this.features = {...this.features,
        enterprise_only: this.features.supplier_only ? false: this.features.enterprise_only,
    }
  }

  onSubmit() {
    /* Website Validation */
    let regExp = new RegExp('^((https?|ftp)://)?([a-z]+[.])?[a-z0-9-]+([.][a-z]{1,4}){1,2}(/.*[?].*)?$');
    if(!regExp.test(this.enterpriseInfo.website)){
      this.globals.showErrorMessage('Please enter valid website.');
      return;
    }

    if(!this.features.goals && !this.features.actions && !this.features.calculator){
        this.globals.showErrorMessage('At least one core feature should be enabled');
        return;
    }

    let preferences = {features:this.features};
    this.enterpriseInfo = new Enterprise({...this.enterpriseInfo, preferences});  

    if (this.type == 'NEW') {
      this.globals.showLoading();
      this.appDataServices.enterpriseService.add(this.enterpriseInfo).subscribe(
        (result) => {
          this.globals.hideLoading();
          this.event.emit('true');
          if(result){
            this.globals.showSuccessMessage("Enterprise Created successfully");
            this.bsModalRef.hide();
          }else {
            this.globals.showErrorMessage('Enterprise email already exists. Please Choose other.');
          }

        }, (err) => {
          this.globals.hideLoading();
        });
    } else {
      this.globals.showLoading();
      this.appDataServices.enterpriseService.update(this.enterpriseInfo).subscribe(
        (result) => {
          this.globals.hideLoading();
          this.event.emit('true');
          this.globals.showSuccessMessage("Enterprise updated successfully");
          this.bsModalRef.hide();
       }, (err) => {
          this.globals.hideLoading();
        });
    }
  }

  handleAddressChange(event: any) {
    setTimeout(() => {
        this.enterpriseInfo.address = event.target.value || '';
      }, 2000);
  }

  deleteProfilePhoto() {
    this.enterpriseInfo.logo = "assets/img/noImage.png";
  }
  selectFile(fileInput) {
    fileInput.value = '';
    fileInput.click();
  }
  onFileChanged(event:any) {
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    let fileZie = event.target.files[0].size;
    if(fileZie <= 512000){
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        this.enterpriseInfo.logo = e.target.result;
      }, ((err:any) => {
        this.enterpriseInfo.logo = "assets/img/noImage.png";
      });
    }else{
      this.globals.showErrorMessage('Logo max size is 500KB.');
      return;
    }

  }

  deleteEnterprise(){    
    this.globals.showConfirm('<span style="font-size:large;">Are you sure you want to delete </span> <span style="font-size:large; color:red; font-weight:bold;">'+this.enterpriseInfo.name+' ?</span>',
    true, true, 'Delete', 'Cancel','confirm-danger','confirm-success')
    .subscribe((result) => {
      if (result) {
        this.sendDeleteRequestToServer();
      }
    });
  }

  sendDeleteRequestToServer(){
    this.globals.showLoading();
    this.appDataServices.enterpriseService.delete(this.enterpriseInfo).subscribe(
      (result) => {
        this.bsModalRef.hide();
        this.event.emit('true');
        this.globals.hideLoading();
        this.globals.showSuccessMessage("Enterprise Deleted successfully");
      }, (err) => {
        this.globals.hideLoading();
      });
  }


}
