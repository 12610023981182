import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { GlobalService } from '@common/global/app.global.service';
import { UserLog } from '@common/models/userLog';
import { AppDataServices } from '@store/entity/entity-services';
import { Constants } from '@common/global/app.global.constants';

@Component({
  selector: 'app-team-create',
  templateUrl: './team-create.component.html',
  styleUrls: ['./team-create.component.scss']
})
export class TeamCreateComponent implements OnInit {
  public eventT: EventEmitter<any> = new EventEmitter();
  public individualData;
  public modalType;
  public teamMembers;
  public teamManagers;
  public teams;
  roles = Constants.roles;
  userLogs: UserLog = new UserLog();
  ButtonText: string = "Save";
  selectedTeamManager: any;
  allTeamManagers: any = [];
  allUsers: any = [];
  teamManager: any;


  teamInfo:any = {
    "name": "",
    "description": "",
    "email": "",
    "color": "#fff",
    "enterpriseId": this.globals.currentEnterpriseId,
    "teamManagerId": null,
    "teamMembers": [],
  };

  constructor(private appDataServices: AppDataServices, public bsModalRef: BsModalRef, private globals: GlobalService, private modalService: BsModalService) {
    this.teamInfo.enterpriseId = this.globals.currentEnterpriseId;
  }

  ngOnInit() {
    if (this.modalType == 'EDIT') {
      this.teamInfo = {
        ...this.individualData,
        teamManagerId: this.individualData.teamManager || this.individualData.teamManagerId
      };
    }


  }

  get isTeamManager(){
    return this.globals.getRole(this.roles.TEAM);
  }



  triggerSelectedTeamManager() {
    // console.log(this.teamInfo);

  }

  triggerSelectedusers() {
    // console.log(this.teamInfo);
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  createNewTeam() {
    let team = {...this.teamInfo,
      email: this.teamInfo.teamManagerId.email || '',
      teamManagerId: this.teamInfo.teamManagerId.id || null,
      teamMembers : (this.teamInfo.teamMembers||[]).map(item=>+(item.id || item.userId)),
    };
    
    if (this.modalType == 'EDIT') {
      this.globals.showLoading();      
      this.appDataServices.teamService.update(team).subscribe(
        (result) => {
          this.globals.hideLoading();
          this.eventT.emit('true');
          this.bsModalRef.hide();
        }, (err) => {
          this.globals.hideLoading();
        });
    } else {
     
      if (this.teams.length == 0) {
        this.addTeam(team);
      } else {
        let teamExists = this.teams.find(item=> item.name==team.name);

        if (!teamExists) {
          this.addTeam(team);
        } else if(teamExists.name==team.name) {
          this.globals.showErrorMessage('Team Already Exists. Choose Different Name!');
        }
      }
    }
  }

  addTeam(team) {
    this.globals.showLoading();    
    this.appDataServices.teamService.add(team).subscribe(
      (result) => {        
        this.globals.hideLoading();
        this.eventT.emit('true');
        this.bsModalRef.hide();
        this.createUserLog('Team created successfully.',JSON.stringify(team));

      }, (err) => {
        this.globals.hideLoading();
      });
  }

  createUserLog(activity, description){
    this.appDataServices.userService.createUserLog(activity, description).subscribe();
  }

  openDeleteConfirmDialog(){
    /*swal.fire({
      html:'<span style="font-size:large;">Are you sure you want to delete? </span> <span style="font-size:large; color:red; font-weight:bold;"> ' + this.teamInfo.name + ' ?</span>',
      showCancelButton: true,
      confirmButtonColor: 'var(--green-90-70)',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      imageUrl:'assets/img/Question-48.png',
      iconHtml: '<img src="assets/img/Question-48.png" style="width:45px; height:45px;"/>',
      allowOutsideClick: false,
        showClass: {
          popup: 'animated fadeInDown faster'
        },
        hideClass: {
          popup: 'animated fadeOutUp faster'
        },
        allowEscapeKey: false
    })*/
    let msg= '<span style="font-size:large;">Are you sure you want to delete </span> <span style="font-size:large; color:red; font-weight:bold;">' + this.teamInfo.name +' ?</span>';
    this.globals.showConfirm(msg, true, true, 'Delete', 'Cancel', 'confirm-danger','confirm-success')
    .subscribe((result) => {
      if (result) {
        setTimeout(() => this.sendDeleteTeamRequestToServer(), 100); 
      }
    })
  }

  sendDeleteTeamRequestToServer(){
    this.globals.showLoading();
    this.appDataServices.teamService.delete(this.teamInfo).subscribe(
      (result) => {
        this.globals.hideLoading();
        // console.log("TEAM DELETED SUCCESSFULLY");
        // console.log(result);
       // this.globals.showSuccessMessage('Team deleted successfully.');
        //this.createUserLog('Team deleted successfully.', this.teamInfo.id);
        this.closeModal()
        //this.loadTeams();


      },(err)=>{
        this.globals.hideLoading();
      });
  }

}
