<form  novalidate (ngSubmit)="fp.form.valid && onSubmit()" #fp="ngForm" style='border-color: transparent; border-radius: 10px;'>
    <div class="modal-header border-0" style="background-color: transparent;  color: var(--btn-primary-color); ">
       <div style="width:100%; font-size:large; ">{{strChangePassword}}</div>
       <button style="color:var(--btn-primary-color);" type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
       <span aria-hidden="true" style="color:var(--btn-primary-color);">&times;</span>
       </button>
    </div>
    <div class="modal-body py-0" style=" background-color: transparent;">
       <div class="form-group">
          <label for="name">{{strCurrentPassword}} <span style="color:red;">*</span></label>
          <input type="password" value="" class="form-control" autocomplete="off" placeholder="Current Password" [(ngModel)]="current_password" name="current_password_" #current_password_="ngModel" [ngClass]="{ 'is-invalid': (fp.submitted || !fp.pristine) && current_password_.invalid }" required>
          <div *ngIf="(fp.submitted || !fp.pristine) && current_password_.invalid" class="invalid-feedback">
             <div *ngIf="current_password_.errors.required"> {{strCurrentPasswordRequired}}</div>
          </div>
       </div>
       <div class="form-group">
          <label for="name">{{strNewPassword}} <span style="color:red;">*</span></label>
          <input type="password" value="" class="form-control" autocomplete="off" placeholder="New Password" [(ngModel)]="new_password" name="new_password_" #new_password_="ngModel" [ngClass]="{ 'is-invalid': (fp.submitted || !fp.pristine) && new_password_.invalid }" required>
          <div *ngIf="(fp.submitted || !fp.pristine) && new_password_.invalid" class="invalid-feedback">
             <div *ngIf="new_password_.errors.required"> {{strNewPasswordRequired}}</div>
          </div>
       </div>
       <div class="form-group">
          <label for="name">{{strConfirmPassword}} <span style="color:red;">*</span></label>
          <input type="password" value="" class="form-control" autocomplete="off" placeholder="Confirm Password" [(ngModel)]="confirm_password"  name="confirm_password_" #confirm_password_="ngModel" [ngClass]="{ 'is-invalid': (fp.submitted || !fp.pristine) && confirm_password_.invalid }" required>
          <div *ngIf="(fp.submitted || !fp.pristine) && confirm_password_.invalid" class="invalid-feedback">
             <div *ngIf="confirm_password_.errors.required"> {{strConfirmPasswordRequired}}</div>
          </div>
       </div>
       <div class="text-right" style="color:red;"><small>* Required</small></div>
    </div>
    <div class="modal-footer justify-content-center border-0 ">
       <button type="submit"  class="btn btn-primary" style="background-color: var(--btn-primary-color); border-radius: 10px;">
       <i class="fa fa-lock"></i> {{strChangePassword}}</button>
       <button type="button" class="btn btn-danger close-button-color" (click)="bsModalRef.hide()" style='border-radius: 10px; border-color: red;'> 
       <i class="fa fa-close"></i> {{strBtnClose}}</button>
    </div>
    </form>