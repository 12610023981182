<div class="p-3">
  <div class="d-flex flex-row">
    <div class="w-100">
      <h4 class="title">Teams</h4>
      <small class="ps-1 text-fade" *ngIf="!isTeamOrEnterpriseManager">Contact your team manager to invite you to your team</small>
    </div>
    <div class="ml-auto toolbar w-100 d-flex flex-row align-items-center justify-content-end gap-2">
      <div class="w-auto form-group position-relative has-search mb-0 pe-2">
        <span class="fa fa-search form-control-feedback fa-lg" style="line-height: 2.3;"></span>
        <input type="text" (keyup)="refreshSearch()" class="form-control w-100" [(ngModel)]="searchText" #txtSearch
          placeholder="Search members">          
      </div>        
          <mat-select (selectionChange)="filterTeam()" [(ngModel)]="selectedTeam" class="btn-primary px-2 border-bottom-0">
            <mat-option selected value="">All Teams</mat-option>
            <mat-option *ngFor="let team of allTeams" [value]="team.id">{{team.name || ''}}</mat-option>
          </mat-select>
          <button (click)="createTeam()" *ngIf="isTeamOrEnterpriseManager" class="btn btn-primary" >
            <i class="fa fa-plus mr-2"></i>Add
          </button>
    </div>
  </div>
  

  <div class="d-flex flex-column" *ngIf="allTeams.length > 0 ">
    <div class="border-top mt-4" *ngFor="let team of filteredTeams">
      <h4 class="text-muted pl-0 text-uppercase " [class.btn]="isTeamOrEnterpriseManager" (click)="editTeam(team)">{{team.name}}</h4>      
      <div class="d-flex flex-row flex-wrap gap-2">
        <div class="card text-center p-0 mr-3"  *ngFor="let user of team.teamMembers   | searchpipe: searchBy; let index = index" (click)="openIndividualModal(team, user)" >
          <img class="avatar" [src]="user.profilePhoto || 'assets/img/account.svg'" [class.opacity-25]="!user.profilePhoto || user.profilePhoto==='assets/img/account.svg'">
          <h4 class="name my-0"> {{user.name || (user.firstName + ' ' + user.lastName)}} </h4>
          <h6 class="email my-0 mx-1 px-1">{{user.email || '&nbsp;'}}</h6>
          <h6 class="bar">{{designation(user) || '&nbsp;'}}</h6>
        </div>
      </div>
    </div>
  </div> 

</div>
