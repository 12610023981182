import { AppDataServices } from '@store/entity/entity-services';
import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import * as $ from 'jquery';
import { Constants } from '@common/global/app.global.constants';
import { GlobalService } from '@common/global/app.global.service';
import { UserLog } from '@common/models/userLog';
import { Buffer } from 'buffer';

@Component({
	selector: 'app-changepassword',
	templateUrl: './changepassword.component.html',
	styleUrls: ['./changepassword.component.css']
})
export class ChangepasswordComponent implements OnInit {

	/* Strings Section */
	strChangePassword: string = null;
	strCurrentPassword: string = null
	strNewPassword: string = null;
	strConfirmPassword: string = null;
	strCurrentPasswordRequired: string = null;
	strNewPasswordRequired: string = null;
	strConfirmPasswordRequired: string = null;
	strConfirmPasswordNotMatched: string = null;
	strBtnClose: string = null;

	current_password: string = null;
	new_password: string = null;
	confirm_password: string = null;

	userLogs: UserLog = new UserLog();

	angChangePassword: UntypedFormGroup;
	currentUser:any = {};
	constructor(public bsModalRef: BsModalRef, private fb: UntypedFormBuilder,
		private appDataServices: AppDataServices, private globals: GlobalService) {
		this.initProcess();
	}

	initProcess() {
		this.strChangePassword = Constants.MSG_CHANGE_PASSWORD;
		this.strNewPassword = Constants.MSG_NEW_PASSWORD;
		this.strCurrentPassword = Constants.MSG_CURRENT_PASSWORD;
		this.strConfirmPassword = Constants.MSG_CONFIRM_PASSWORD;
		this.strCurrentPasswordRequired = Constants.MSG_CURRENT_PASSWORD_REQUIRED;
		this.strNewPasswordRequired = Constants.MSG_NEW_PASSWORD_REQUIRED;
		this.strConfirmPasswordRequired = Constants.MSG_CONFIRM_PASSWORD_REQUIRED;
		this.strConfirmPasswordNotMatched = Constants.MSG_CONFIRM_PASSWORD_NOT_MATCHED;
		this.strBtnClose = Constants.BTN_CLOSE;
		this.appDataServices.authService.currentUser$.subscribe(item=>this.currentUser=item);
	}

	onSubmit() {
		if(!this.currentUser && !this.currentUser.user)
		return;

		let cpwdObj = {
			username: Buffer.from(this.currentUser.user.username, 'ascii').toString('base64'),
			oldPassword: Buffer.from(this.current_password, 'ascii').toString('base64'),
			newPassword: Buffer.from(this.new_password, 'ascii').toString('base64')
		}
		
		if (this.new_password == this.current_password) {
			this.globals.showErrorMessage("Current password & new Password can't be same.");
			return;
		} else if (this.new_password != this.confirm_password) {
			this.globals.showErrorMessage("New password & confirm Password does not match.");
			return;
		} else {
			this.globals.showLoading();

			this.appDataServices.authService.changePassword(cpwdObj).subscribe((result) => {
				this.globals.hideLoading();

				if (result) {
					/*swal.fire({
						title: 'Password Changed Successfully. ',
						html: '<span style="font-size:medium; color: #0072bb;">You will be redirected to login page.</span>',
						allowEscapeKey: false,
						showCloseButton: false,
						imageUrl: 'assets/img/OK-48.png',
						allowOutsideClick: false,
						showClass: {
							popup: 'animated fadeInDown faster'
						},
						hideClass: {
							popup: 'animated fadeOutUp faster'
						}
					})*/
					let msg= `<span style="font-size:large;">Password Changed Successfully. </span><br>
					<span style="font-size:medium; color: #0072bb;">You will be redirected to login page.</span>`;
				  this.globals.showConfirm(msg, false, false)				  
					.subscribe((result) => {
						this.bsModalRef.hide();
						this.appDataServices.authService.logout();
						this.createUserLog('Password Changed Successfully.',cpwdObj);
					});
				}
			}, (error) => {
				this.globals.hideLoading();
			});

		}




	}

	ngOnInit() {
	}

	createUserLog(activity, description){
		this.appDataServices.userService.createUserLog(activity, description).subscribe();
	}
}